import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import DefaultLayoutSidebar from "../components/DefaultLayoutSidebar"
import CustomNavTitle from "../components/CustomNavTitle"

import illustration2x from "../images/illustrations/illustration-patient-consent@2x.png"

import { theme } from "../styles/theme"

const PatientConsent = ({ location }) => {
  return (
    <Layout>
      <SEO title="Patient Consent Considerations" />

      <CustomNavTitle
        title="Patient Consent Considerations"
        destination="/patient-consent"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <p css={theme.components.title}>Consider:</p>
          <ul className="spaced mt-gap-2">
            <li>How do you know the patient understood the information?</li>
            <li>
              The patient's age, communication needs, existing conditions and
              potential impact on future treatment.
            </li>
            <li>
              Asking the patient "What matters to you?", allow time for
              consideration and revisit the conversation if possible.
            </li>
            <li>
              If all suitable alternatives have been explained, including
              refusal of treatment, and the risks and benefits of these.
            </li>
            <li>
              How patient information leaflets should be used i.e. to support,
              not replace, the conversation. Translation services should
              be accessed as required.
            </li>
            <li>
              Involving friends and family in these discussions according to
              the patient's wishes.
            </li>
            <li>Local policy for how consent should be documented.</li>
            <li>Local guidelines for the assessment of capacity.</li>
            <li>
              That a patient has a right to know about their transfusion and its
              potential significance in the future.
            </li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default PatientConsent
